<template>
    <div
      id="Carousel"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner" style="height:810px;">
        <div class="carousel-item active" data-bs-interval="15000">
          <div class="d-flex align-items-center justify-content-center">
            <img :src="envGetImagePath('price_cn.png')" class="d-block p-4"/>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="15000">
          <div class="d-flex align-items-center justify-content-center">
            <img :src="envGetImagePath('price_en.png')" class="d-block p-4"/>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import getEnv from '@/utils/env.js';
export default {
  mounted() {
    document.title = getEnv('VUE_APP_TITLE') + '中英價目表';
  }
};
</script>
<style scoped>
.dashboard-style {
  background-image: linear-gradient(#0774ea, #07368f);
  width: 100vw;
  height: 100vh;
}
.text-color-blue {
  color: #3277e0;
}
.text-color-descr-dark {
  color: #575757;
}
.round-describe {
  background-color: #ffffff;
  border-radius: 13px;
  width: fit-content;
  height: 72px;
}
.round-describe .text {
  text-align: left;
  font: bold 43px/2px Noto Sans;
  color: #004ea2;
}
.descibe-to-station {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-to-station .cn-station {
  font-size: 116px;
  font: bold Noto Sans;
}
.descibe-to-station .en-station {
  font-size: 84px;
  font: bold 89px/3px Montserrat;
}
.pop-add-ship {
  font-size: 45px;
  letter-spacing: 1.8px;
  font-weight: 900;
  font-family: Noto Sans;
}
.descibe-ship-time {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-ship-time .ship-time {
  font-size: 110px;
  font-weight: bold;
  font-family: Montserrat;
}
.descibe-ship-time .ship-name {
  font-size: 110px;
  font-weight: bold;
  font-family: Noto Sans;
}

.date {
  height: 180px;
}
.date .date-left {
  text-align: left;
  font-size: 130px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
}
.date .date-right {
  text-align: left;
  font-size: 50px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
  letter-spacing: 2.12px;
}

.circle-progress-layout {
  height: 600px;
}
.circle-progress-layout .passenger {
  font-size: 180px;
  font-weight: bold;
}
.circle-progress-layout .passenger-desc {
  font-size: 50px;
}
.passenger-limit {
  color: #ffffff;
  height: 100px;
}
.passenger-limit .desc {
  font-size: 48px;
  width: fit-content;
  font-weight: 900;
  font-family: Noto Sans;
}
.passenger-limit .number {
  font-size: 135px;
  width: fit-content;
  height: fit-content;
  font-weight: bold;
  font-family: Montserrat;
}

.h-30 {
  height: 30vh;
}
</style>
