export default {
    methods: {
        getToday() {
          let date = new Date();
          let yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // 1月為 0 ;
          let dd = date.getDate();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          
          return yyyy + "-" + mm + "-" + dd;
        },
        getToday_DashBoard() {
          let date = new Date();
          let yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // 1月為 0 ;
          let dd = date.getDate();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }
          
          return {
            year: yyyy,
            md: mm + "月" + dd + "日",
            day: this.getWeekday( yyyy+'-'+mm+'-'+dd ),
          };
        },
        getNowTime: function() {
          // 取得現在 hr:min ;
          let today = new Date() ;
          let hr = today.getHours() ;
          let min = today.getMinutes() ;
          if (hr < 10) {
              hr = '0' + hr ;
          }
          if (min < 10) {
              min = '0' + min ;
          }
          
          return ( hr + ':' + min ) ; 

        },
        getWeekday: function( _time ) {
          // date => weekday ;
          let day = new Date(_time) ;
          var weekday = new Array(7);
          weekday[0] = "日";
          weekday[1] = "一";
          weekday[2] = "二";
          weekday[3] = "三";
          weekday[4] = "四";
          weekday[5] = "五";
          weekday[6] = "六";
          return weekday[day.getDay()] ;
        },
        getMMDD: function( _yyyymmdd ) {
            // 將yyyymmdd除去年份 ;
            return _yyyymmdd.substring(5,11) ;
        },
        generateDateString( y, m, d ) {
          if ( m < 10 ) {
            m = "0" + m;
          }
          if ( d < 10 ) {
            d = "0" + d;
          }
          return y + '年' + m + '月' + d + '日' + this.getWeekday(y+'-'+m+'-'+d) ;
        },
        dateStringToYMDObject( str ) {
          let strtemp = str;
          let y = strtemp.split( '年', 2 ); // 2021, 06月30日() ;
          strtemp = y[1];
          y = y[0]; // 2021 ;

          let m = strtemp.split( '月', 2 ); // 06, 30日()
          strtemp = m[1];
          m = m[0];

          let d = strtemp.split( '日', 1 ); 
          d = d[0];
          
          // console.log('y-m-d', y, m, d);
          return {
            y: y,
            m: m,
            d: d,
          }

        },
        dateStringToYMDString( str ) {
          if ( str != "" ) {
            let object = this.dateStringToYMDObject(str);
            return object.y + '-' + object.m + '-' + object.d ;
          }
          else {
            return null;
          }
        },
        hmToMinutes( hm ) {
          let hm_split = hm.split(':') ;
          // console.log('hr',hm_split[0],'min',hm_split[1])
          return parseInt(parseInt(hm_split[0])*60 + parseInt(hm_split[1])); 
        },
    }
    
}