<template>
  <div>
    <div class="fixed-top-center h-25 w-25">
      <div class="h-25 d-flex align-items-center justify-content-around">
        <div class="fixedtop-station">
          <span v-if="route==='DL'">東港<br><span class="en">Donggang</span></span>
          <span v-if="route==='LD'">小琉球<br><span class="en">LIUQIU</span></span>
        </div>
        <div class="arrow_right m-4">
          
        </div>
        <div class="fixedtop-station"> 
          <span v-if="route==='LD'">東港<br><span class="en">Donggang</span></span>
          <span v-if="route==='DL'">小琉球<br><span class="en">LIUQIU</span></span>
        </div>

      </div>
    </div>
    <div class="h-75 col-12">
        <div class="mx-auto col-10 row" v-if="!loading">
            <span class="col-6">
                <div 
                  v-for="(item, index) in leftList" :key="index"
                > 
                    <ShipItem 
                    :_shipdata="item"
                    />
                </div>
            </span>
            <span class="col-6">
                <div 
                  v-for="(item, index) in rightList" :key="index"
                > 
                    <ShipItem 
                    :_shipdata="item"
                    />
                </div>
            </span>
            
        </div>
        
        <!-- <div v-for="(item, index) in shippingList" :key="index">

        </div> -->
    </div>
  </div>

  <!-- <div class="dashboard-style row">
        <div class="row p-4 fw-bold">
            <div class="col-2">
                <div class="text-color-blue vh-date">
                    <div class="h-50 year">{{date.year}}</div>
                    <div class="h-50 md">{{date.md}}</div>
                </div>
                <div class="station-round text-white vh-station d-flex align-items-center justify-content-center py-4">
                    <div>
                        <div class="text">{{station.from}}</div>
                        <div class="fw-bolder text py-2">|</div>
                        <div class="text">{{station.to}}</div>
                    </div>
                </div>
            </div>
            <div class="col-10 row">
                <div 
                  v-for="(item,index) in shippingList" :key="index"
                  class="col-4 d-flex align-items-center justify-content-center"
                >
                    <div
                     class="ship-time w-100 my-2"
                     :class="{'now-ship': item.data.time === nowShip.time }">
                        {{item.data.time}}
                    </div>
                </div>
                <div class="d-flex justify-content-end align-items-end">
                    <img src="@/assets/logo.svg" alt="">
                </div>
            </div>
            

        </div>
    </div> -->
</template>
<script>
import { apiGetSailings } from "@/apis/booking.js";
import ShipItem from "@/components/shipping-component.vue";
import getEnv from '@/utils/env.js';
export default {
  components: {
    ShipItem
  },
  props: {
    route: String,
  },
  data() {
    return {
      date: {
        year: "",
        md: "",
        day: "",
        time: "00:00",
      },
      station: {
        from: "",
        to: "",
      },

      shippingList: [],
      loading: true,
      nextShipFounded: false,
    };
  },
  computed: {
    leftList() {
        let array = [];
        for ( let i = 0 ; i < 5 ; i++ ) {
            array.push(this.shippingList[i]);
        }
        return array;
    },
    rightList() {
        let array = [];
        for ( let i = 5 ; i < 10 ; i++ ) {
            array.push(this.shippingList[i]);
        }
        return array;
    }
  },
  methods: {
    startSetInterval() {
      window.setInterval(() => this.setDashboardTime(), 1000);
      window.setInterval(
        () => this.getShip(this.getToday(), this.route),
        30000
      );
    },
    setDashboardTime() {
      let getDate = this.getToday_DashBoard();
      let getTime = this.getNowTime();
      this.date.md = getDate.md;
      this.date.day = getDate.day;
      this.date.time = getTime;
    },
    init() {
      this.getStation();
      this.setDashboardTime();
      this.getShip(this.getToday(), this.route);
      this.startSetInterval();
    },
    getNowShip() {
      for (let i = 0; i < this.shippingList.length; i++) {
        if (!this.shiplist[i].disable) {
          this.nowShip.time = this.shippingList[i].data.time;
          this.nowShip.quota = this.shippingList[i].data.quota;
          this.nowShip.used = this.shippingList[i].data.pickup;
          break;
        }
      }
    },
    getStation() {
      if (this.route === "DL") {
        this.station.from = "東港";
        this.station.to = "小琉球";
      } else if (this.route === "LD") {
        this.station.from = "小琉球";
        this.station.to = "東港";
      }
    },
    async getShip(date, routeCode) {
      // api 取得 航班時間;
      this.loading = true ;
      this.nextShipFounded = false ;

      let data = {
        date: date,
        route_code: routeCode,
      };
      this.shippingList = [] ;

      let searchResult = await apiGetSailings(data);
      if (searchResult.status === 200) {
        for (let i = 0; i < searchResult.data.length; i++) {
          let oneRow = {
            data: searchResult.data[i],
            disable: false,
            isSpecial: false,
            isFull: false,
            isStop: false,
            isNextShip: false,
          };
          oneRow.disable = !this.checkTime(date, oneRow.data.time);
          oneRow.isSpecial = oneRow.data.type === 'speical' || oneRow.data.type === 'special' ? true : false ;
          // if (oneRow.data.pickup >= oneRow.data.quota) {
          //   oneRow.disable = true;
          //   oneRow.isFull = true;
          // }

          if (!oneRow.disable && !this.nextShipFounded) {
            oneRow.isNextShip = true ;
            this.nextShipFounded = true;
          }

          if (!oneRow.disable&&!oneRow.isSpecial)
            this.shippingList.push(oneRow);

        }
      } else {
        console.log(searchResult);
      }

      this.loading = false;
    },
    checkTime(date, time) {
      // 檢查輸入的時間是否過期(超過現在時間) ;
      if (date > this.getToday()) {
        return true;
      } else if (date === this.getToday() && time > this.getNowTime()) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.init();
    if ( this.route === 'DL' )
      document.title = getEnv('VUE_APP_TITLE') + '東港時刻表';
    else if ( this.route === 'LD' )
      document.title = getEnv('VUE_APP_TITLE') + '小琉球時刻表';
  },
};
</script>
<style scoped>
.text-color-blue {
  color: #3277e0;
}
.text-color-descr-dark {
  color: #575757;
}
.round-describe {
  background-color: #ffffff;
  border-radius: 13px;
  width: fit-content;
  height: 72px;
}
.round-describe .text {
  text-align: left;
  font: bold 43px/2px Noto Sans;
  color: #004ea2;
}
.descibe-to-station {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-to-station .cn-station {
  font-size: 116px;
  font: bold Noto Sans;
}
.descibe-to-station .en-station {
  font-size: 84px;
  font: bold 89px/3px Montserrat;
}
.pop-add-ship {
  font-size: 45px;
  letter-spacing: 1.8px;
  font-weight: 900;
  font-family: Noto Sans;
}
.descibe-ship-time {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-ship-time .ship-time {
  font-size: 110px;
  font-weight: bold;
  font-family: Montserrat;
}
.descibe-ship-time .ship-name {
  font-size: 110px;
  font-weight: bold;
  font-family: Noto Sans;
}

.date {
  height: 180px;
}
.date .date-left {
  text-align: left;
  font-size: 130px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
}
.date .date-right {
  text-align: left;
  font-size: 50px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
  letter-spacing: 2.12px;
}

.circle-progress-layout {
  height: 600px;
}
.circle-progress-layout .passenger {
  font-size: 180px;
  font-weight: bold;
}
.circle-progress-layout .passenger-desc {
  font-size: 50px;
}
.passenger-limit {
  color: #ffffff;
  height: 100px;
}
.passenger-limit .desc {
  font-size: 48px;
  width: fit-content;
  font-weight: 900;
  font-family: Noto Sans;
}
.passenger-limit .number {
  font-size: 135px;
  width: fit-content;
  height: fit-content;
  font-weight: bold;
  font-family: Montserrat;
}

.h-30 {
  height: 30vh;
}
.arrow_right {
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 50px solid #ffffff;
  font-size: 0;
  line-height: 0;
  transform: rotate(90deg);
}

.fixed-top-center {
  position: fixed;
  left: 38%;
  top: 10%;
}
.fixedtop-station {
  color: #ffffff;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  line-height: 52px;
}
.fixedtop-station .en {
  font-size: 34px;

}
</style>
