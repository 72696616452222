import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import DateMixin from './mixin/DateMixin.js'
import EnvVariableMixin from './mixin/EnvVariableMixin.js'
import 'vue-smooth-picker/dist/css/style.css'
import VueEllipseProgress from 'vue-ellipse-progress';
import SmoothPicker from 'vue-smooth-picker'
Vue.use(SmoothPicker)
Vue.use(VueEllipseProgress);

Vue.config.productionTip = false
Vue.mixin(DateMixin)
Vue.mixin(EnvVariableMixin)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
