<template>
    <div class="my-4" v-if="_shipdata !== undefined">
        <div 
         class="px-4 py-2 h-auto shipitem-style d-flex justify-content-center"
         :class="{
             isNextShip: _shipdata.isNextShip, // 下一班 ;
             isDisable: _shipdata.disable, // 超過時間 ;
             isStop: _shipdata.isStop, // 停駛
         }"
        >
            <div 
             class="mx-3 w-50 text-end"
            >
                <div>
                    <div
                     
                    >
                    <!-- :class="{ 'special-time': _shipdata.isSpecial }" -->
                        {{_shipdata.data.time}}
                    </div>
                    <!-- <div 
                     class="special px-4 mx-4"
                     v-if="_shipdata.isSpecial"
                    >
                    加班
                    </div> -->
                </div>
                
            </div>
            <div class="mx-3 w-50 text-start">
                <span v-if="_shipdata.isStop">停駛</span>
                <span v-else>{{_shipdata.data.ferry_name}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        _shipdata: Object
    },

}
</script>
<style scope>
.shipitem-style {
    border: 1px solid #ffffff;
    border-radius: 30px;
    background-color: var(--maincolor);
    font-size: 70px;
    color: #ffffff;
    font-weight: bold;
}

.shipitem-style.isNextShip {
    border-width: 3.2px;
    border-color: #ffc107;
}
.shipitem-style.isDisable {
    background-color: #939393;
    color: #D2D2D6;
}
.shipitem-style.isStop {
    background-color: #FF537B;
    color: #ffffff;
}

.shipitem-style .special-time {
    font-size: 60px;
    line-height: 65px;
}
.shipitem-style .special {
    font-size: 38px;
    color: #FC426D;
    line-height: 20px;
}

</style>