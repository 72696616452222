<template>
    <div
      id="Carousel"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner" style="height:810px;">
        <div class="carousel-item active">
          <div class="p-2 px-4 d-flex justify-content-around">
            <div class="col-6">
              <div class="row p-4 m-4">
                <div
                  class="px-4 round-describe d-flex align-items-center justify-content-center"
                >
                  <span class="text">目的地</span>
                </div>
                <div class="descibe-to-station my-4">
                  <span class="cn-station">{{ station.to }}</span>
                  <span class="en-station mx-4">{{ station.to_en }}</span>
                </div>

                <div class="my-4"></div>

                <div class="d-flex p-0">
                  <div
                    class="px-4 round-describe d-flex align-items-center justify-content-center"
                  >
                    <span class="text">出發時間</span>
                  </div>
                  <div
                    class="px-4 mx-4 text-white d-flex align-items-end pop-add-ship"
                  >
                    <span v-if="nowShip.isSpecial">機動加班</span>
                  </div>
                </div>

                <div class="descibe-ship-time my-4">
                  <span class="ship-time">{{ nowShip.time }}</span>
                  <span class="ship-name px-2 mx-4">{{
                    nowShip.ferryName
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div
                class="row circle-progress-layout d-flex align-items-center justify-content-center"
              >
                <vue-ellipse-progress
                  :progress="progressData"
                  color="#ffffff"
                  :emptyColor="{
                    colors: [{ color: '#ffffff', offset: '0', opacity: '0.2' }],
                  }"
                  :size="550"
                  fontSize="4rem"
                >
                  <div class="text-white">
                    <div class="passenger-desc">已登船</div>
                    <div>
                      <span class="passenger">{{ nowShip.used }}</span>
                      人
                    </div>
                  </div>
                </vue-ellipse-progress>
              </div>
              <div
                class="row d-flex align-items-center justify-content-center passenger-limit"
              >
                <span class="desc pt-4">乘載人數</span>
                <span class="number pb-4">{{ nowShip.seat }}</span>
                <span class="desc pt-4">人</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="carousel-item" data-bs-interval="15000">
            <div class="d-flex align-items-center justify-content-center">
                <img v-if="isCNP" src="@/assets/images/price_cn.png" class="d-block p-4"/>
                <img v-if="!isCNP" src="@/assets/images/price_en.png" class="d-block p-4"/>
            </div>
        </div> -->
      </div>
    </div>
</template>
<script>
import { apiGetSailings } from "@/apis/booking.js";
import getEnv from '@/utils/env.js';
export default {
  components: {

  },
  props: {
    route: String,
    isCNP: Boolean,
  },
  data() {
    return {
      date: {
        year: "",
        md: "",
        day: "",
        time: "00:00",
      },
      station: {
        from: "",
        from_en: "",
        to: "",
        to_en: "",
      },
      nowShip: {
        time: "",
        quota: "",
        used: "",
        ferryName: "",
        isSpecial: false,
      },
      shippingList: [],
    };
  },
  computed: {
    progressData() {
      return (this.nowShip.used * 100) / this.nowShip.quota;
    },
  },
  watch: {},
  methods: {
    startSetInterval() {
      window.setInterval(() => this.setDashboardTime(), 1000);
      window.setInterval(() => this.getShip(this.getToday(), this.route), 5000);
    },
    setDashboardTime() {
      let getDate = this.getToday_DashBoard();
      let getTime = this.getNowTime();
      this.date.md = getDate.md;
      this.date.day = getDate.day;
      this.date.time = getTime;
    },
    init() {
      // this.date = this.getToday_DashBoard();
      this.getStation();
      this.setDashboardTime();
      this.getShip(this.getToday(), this.route);
      this.startSetInterval();
    },
    getNowShip() {
      for (let i = 0; i < this.shippingList.length; i++) {
        if (!this.shiplist[i].disable) {
          this.nowShip.time = this.shippingList[i].data.time;
          this.nowShip.quota = this.shippingList[i].data.quota;
          this.nowShip.used = this.shippingList[i].data.boarding;
          this.nowShip.ferryName = this.shippingList[i].data.ferryName;
          break;
        }
      }
    },
    getStation() {
      if (this.route === "DL") {
        this.station.from = "東港";
        this.station.from_en = "Donggang".toUpperCase();
        this.station.to = "小琉球";
        this.station.to_en = "Liuqiu".toUpperCase();
      } else if (this.route === "LD") {
        this.station.from = "小琉球";
        this.station.from_en = "Liuqiu".toUpperCase();
        this.station.to = "東港";
        this.station.t0_en = "Donggang".toUpperCase();
      }
    },
    async getShip(date, routeCode) {
      // api 取得 航班時間;
      let data = {
        date: date,
        route_code: routeCode,
      };
      this.shippingList = [];

      let searchResult = await apiGetSailings(data);
      if (searchResult.status === 200) {
        for (let i = 0; i < searchResult.data.length; i++) {
          let oneRow = {
            data: searchResult.data[i],
            disable: false,
            isSpecial: false,
            isFull: false,
          };
          oneRow.disable = !this.checkTime(date, oneRow.data.time);
          // if ( oneRow.data.boarding >= oneRow.data.quota ) {
          //     oneRow.disable = true;
          //     oneRow.isFull = true;
          // }

          this.shippingList.push(oneRow);

          if (!oneRow.disable) {
            this.nowShip.time = oneRow.data.time;
            this.nowShip.quota = oneRow.data.quota;
            this.nowShip.used = oneRow.data.boarding;
            this.nowShip.ferryName = oneRow.data.ferry_name;
            this.nowShip.seat = oneRow.data.seat;
            break;
          } else if (
            this.nowShip.time === "" &&
            i === searchResult.data.length - 1
          ) {
            this.nowShip.time = oneRow.data.time;
            this.nowShip.quota = oneRow.data.quota;
            this.nowShip.used = oneRow.data.boarding;
            this.nowShip.ferryName = oneRow.data.ferry_name;
            this.nowShip.seat = oneRow.data.seat;
          }
        }
      } else {
        console.log(searchResult);
      }
    },
    checkTime(date, time) {
      // 檢查輸入的時間是否過期(超過現在時間+5min) ;
      // console.log('+',this.hmToMinutes(time),this.hmToMinutes(this.getNowTime()));
      if (date > this.getToday()) {
        return true;
      } else if (
        date === this.getToday() && 
        this.hmToMinutes(time)+5 > this.hmToMinutes(this.getNowTime())
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.init();
    if ( this.route === 'DL' )
      document.title = getEnv('VUE_APP_TITLE') + '東港登船資訊';
    else if ( this.route === 'LD' )
      document.title = getEnv('VUE_APP_TITLE') + '小琉球登船資訊';
  },
};
</script>
<style scoped>
.dashboard-style {
  background-image: linear-gradient(#0774ea, #07368f);
  width: 100vw;
  height: 100vh;
}
.round-describe {
  background-color: #ffffff;
  border-radius: 13px;
  width: fit-content;
  height: 72px;
}
.round-describe .text {
  text-align: left;
  font: bold 43px/2px Noto Sans;
  color: var(--maincolor);
}
.descibe-to-station {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-to-station .cn-station {
  font-size: 116px;
  font: bold Noto Sans;
}
.descibe-to-station .en-station {
  font-size: 84px;
  font: bold 89px/3px Montserrat;
}
.pop-add-ship {
  font-size: 45px;
  letter-spacing: 1.8px;
  font-weight: 900;
  font-family: Noto Sans;
}
.descibe-ship-time {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-ship-time .ship-time {
  font-size: 110px;
  font-weight: bold;
  font-family: Montserrat;
}
.descibe-ship-time .ship-name {
  font-size: 110px;
  font-weight: bold;
  font-family: Noto Sans;
}

.date {
  height: 180px;
}
.date .date-left {
  text-align: left;
  font-size: 130px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
}
.date .date-right {
  text-align: left;
  font-size: 50px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
  letter-spacing: 2.12px;
}

.circle-progress-layout {
  height: 600px;
}
.circle-progress-layout .passenger {
  font-size: 180px;
  font-weight: bold;
}
.circle-progress-layout .passenger-desc {
  font-size: 50px;
}
.passenger-limit {
  color: #ffffff;
  height: 100px;
}
.passenger-limit .desc {
  font-size: 48px;
  width: fit-content;
  font-weight: 900;
  font-family: Noto Sans;
}
.passenger-limit .number {
  font-size: 135px;
  width: fit-content;
  height: fit-content;
  font-weight: bold;
  font-family: Montserrat;
}

.h-30 {
  height: 30vh;
}
</style>
